import { Controller } from "@hotwired/stimulus"

/*
  Form要素にバインドすることでいろいろなアクションを設定できるようにするために作成したControllerです
  @example
    <form data-controller="form-accept">
      <button type="button" data-action="form#clear">クリア</button>
    </form>
 */

export default class extends Controller {
  /** 
   * checkNum: チェックが入っている項目数を表示させるDOMに設定してください
   */
  static targets = ["checkbox", "submit"]

  connect() {
    console.log("form accept connect")
    this.update(this.checkboxTarget.checked)
  }

  change(e) {
    console.log("form accept change")
    this.update(e.target.checked)
  }

  /** private */

  update(isChecked) {
    if (isChecked) {
      this.submitTarget.disabled = false
    } else {
      this.submitTarget.disabled = true
    }
  }
}
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    params: Object,
  }

  /** HTMLにバインドされるときに実行されるコールバックです */
  connect() {
    console.log("action button connect")
  }

  /** Formに通知を送ります */
  sendFormEvent() {
    // 「応募する」ボタンでイベントを発生し、モーダル表示のアクションを起動する。
    console.log("sendFormEvent()")
    const cEvent = this.buildFormEvent()

    window.dispatchEvent(cEvent);
  }

  /**
   * 案件フォーム側で申し込みが完了したときに実行されるコールバックです
   * @param {CustomEvent} event 
   */
  receiveFormEvent(event) {
    console.log("receiveFormEvent()")

    const detail = event.detail
    const actionJob = detail.job
    /** 通知は全てのボタンに対してが送られてくるため判定をいれています */
    if (actionJob.id === this.paramsValue.id) {
      const button = this.targets.element

      // ボタンを変更する
      button.classList.add(this.paramsValue.after_button_class)
      button.textContent = this.paramsValue.after_button_text
      button.disabled = true
    }
  }

  /**
   * Formに通知するイベントを作成して返します
   * @return {CustomEvent}
   */
  buildFormEvent() {
    // 「応募する」ボタンからモーダルに渡すパラメータ
    const params = this.paramsValue
    const detail = {
      job: { id: params.id, title: params.title, career_job: !!params.career_job },
      candidate: { enabled_freelance_service: !!params.enabled_freelance_service, enabled_career_service: !!params.enabled_career_service },
      requestPath: this.paramsValue.request_path,
    }
    return(
      new CustomEvent('jobAction', { detail })
    )
  }
}

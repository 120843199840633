import { Controller } from "@hotwired/stimulus"

// 表示・非表示を切り替えるクラス名
const hiddenClassName = "non-display"

/**
 * ポップアップを制御するコントローラーです
 * ポップアップ: メッセージを表示し、閉じる or メッセージ内リンクの移動しかできないモーダルのことを意味しています
 * */
export default class extends Controller {
  // HTMLにバインドされたときに実行されるコールバックです
  connect() {
    console.log("connect pop up")
  }

  /** ポップアップを閉じます */
  close() {
    this.element.classList.add(hiddenClassName)
  }
}
import { Controller } from "@hotwired/stimulus"

/*
  会員登録完了画面　追加入力のハンドラ
*/
export default class extends Controller {
  static values = {
    freelance: Number, // フリーランスのとき1
    career: Number // キャリアのとき1
  }
  static targets = [
    "workStyle",
    "conditions",
    "workdayNum",
    "startAvailableDate",
    "personalAddressCareer",
    "currentCompanyName",
    "currentAnnualSalary",
    "annualSalary",
    "possibleChangeOfCareer"
  ]

  connect() {
    console.log("signup-appendix connect")
  }

  // 入力項目に変更があったときに呼ばれる
  checkChange() {
    // console.log("signup-appendix checkChange")
    let valid = true // チェック結果
    // すべての項目が入力されるとチェックOKとする。
    if (this.freelanceValue === 1) {
      // フリーランスの項目チェック
      valid &&= this.checkedAny(this.workStyleTarget)
      valid &&= this.checkedAny(this.conditionsTarget)
      valid &&= this.checkedAny(this.workdayNumTarget)
      valid &&= this.startAvailableDateCheck()
    }
    if (this.careerValue === 1) {
      // キャリアの項目チェック
      valid &&= this.personalAddressCareerCheck()
      valid &&= this.hasText(this.currentCompanyNameTarget)
      valid &&= this.hasNumber(this.currentAnnualSalaryTarget)
      valid &&= this.hasNumber(this.annualSalaryTarget)
      valid &&= this.hasChecked(this.possibleChangeOfCareerTarget)
    }
    const submitButton = this.element.querySelector('input[type="submit"]')
    submitButton.disabled = !valid
  }

  // targetのチェックボックスがどれか一つチェックされているときtrue
  checkedAny(target) {
    const checkBoxes = target.querySelectorAll('input[type="checkbox"]')
    let anyChecked = false
    checkBoxes.forEach(ch => anyChecked ||= ch.checked)
    return anyChecked
  }

  // targetのラジオボタンがどれか一つチェックされているときtrue
  hasChecked(target) {
    const radioButtons = target.querySelectorAll('input[type="radio"]')
    let anyChecked = false
    radioButtons.forEach(ra => anyChecked ||= ra.checked)
    return anyChecked
  }

  // targetのテキストに入力があるときtrue
  hasText(target) {
    const textField =  target.querySelector('input[type="text"')
    return textField.value
  }

  // targetの数値入力があるときtrue
  hasNumber(target) {
    const numberField = target.querySelector('input[type="number"')
    return parseInt(numberField.value) > 0
  }

  // 稼働可能時期のチェック
  startAvailableDateCheck() {
    const year = this.startAvailableDateTarget.querySelector("select.customYear").value
    const month = this.startAvailableDateTarget.querySelector("select.month").value
    const day = this.startAvailableDateTarget.querySelector("select.day").value

    // 年月日全て選択されているかチェック
    if (!year || !month || !day) {
      return false
    } else {
      // 存在しない日付でないかをチェック
      var date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day))
      return (date.getFullYear() == parseInt(year) && date.getMonth() == parseInt(month) - 1 && date.getDate() == parseInt(day))
    }
  }

  // キャリアで住所を入力するときのチェック
  personalAddressCareerCheck() {
    return this.hasText(this.personalAddressCareerTarget)
  }
}

import $ from "jquery"


document.addEventListener('turbo:load', function() {
  /**
   * 新着案件のフッター
   */
  const list = $("[data-id='footerNewJobList']")[0]

  /**
   * 案件一覧横の矢印(左)アイコンを押したときのイベント
   */
  $('[data-id="prevButton"]').click(() => {
    list.scrollBy({top: 0, left: -1 * list.clientWidth, behavior: 'smooth'})
  })

  /**
   * 案件一覧横の矢印(右)アイコンを押したときのイベント
   */
  $('[data-id="nextButton"]').click(() => {
    list.scrollBy({top: 0, left: 1 * list.clientWidth, behavior: 'smooth'})
  })
})

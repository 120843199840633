import { Controller } from "@hotwired/stimulus"

/**
 * Marketo に送信するパラメータの href のデータ一式です
 *   本ファイルにまとめることでどのような値をいつ送るかの責務を集約しています
 */
const trackingHrefList = {
  "job_entry": "/job/entry",
  "skill_update": "/skill/update",
  "profile_update": "/profile/update",
  "preference_update": "/preference/update",
  "recommend_accept": "/recommends/accept",
  "recommend_decline": "/recommends/decline",
  "resume_upload": "/attachments/resume_upload",
  "job_resume_upload": "/attachments/job_resume_upload",
}

/**
 * Marketo API を明示的に利用するためのControllerです
 * Marketo: Marketo（マルケト）はエンゲージメント・マーケティング・プラットフォームです。詳細は検索等を行なってください。
 * */
export default class extends Controller {
  static values = {
    /** トラッキング時に送信するデータのタイプを記載してください
     * <... data-marketo-type-value="job_entry">
    */
    type: String,
  }

  // HTMLにバインドされたときに実行されるコールバックです
  connect() {
    console.log("connect Marketo")
  }

  /** MarketoにclickLinkのトラッキングコードを送ります */
  clickLink() {
    const data = this.getTrackingParams(this.typeValue)
    this.trackingSend("clickLink", data)
  }

  /** private */

  /**
   * Marketo にトラッキングデータを送信します
   * typeやdataの形式はMarketoのAPIリファレンスを参照してください。
   * @param {string} type 
   * @param {Object} data 
   * @private
   */
  trackingSend(type, data) {
    console.log(`send tracking. type: ${type}, data: %o`, data)
    if (window.Munchkin) {
      window.Munchkin.munchkinFunction(type, data)  
    } else {
      console.error("window.Munchkin not defined.")
    }
  }

  /**
   * @param {string} type
   * @private
   */
  getTrackingParams(type) {
    const href = trackingHrefList[this.typeValue]

    if (!href) {
      console.error(`${this.typeValue} type is not defined.`)
      return { href: "/unknown" }
    } else {
      return { href }
    }
  }
}
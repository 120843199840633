document.addEventListener('turbo:load', function() {
  /* headUtilityNavの高さを考慮 */
  const buffer = 60;
  const scrollTriggers = document.querySelectorAll('.skillNav a');

  scrollTriggers.forEach(
    function(trigger) {
      trigger.addEventListener('click', function (e) {
        e.preventDefault();
        /* innerHTMLを取得するため HTMLElementにキャストする */
        let eventTarget = e.target as HTMLElement;

        /* リンク内のテキストがidとして指定されている要素にscrollする */
        let scrollTarget = document.getElementById(eventTarget.innerHTML);
        if (scrollTarget) {
          let scrollTargetTop = scrollTarget.getBoundingClientRect().top;
          let offsetTop = window.pageYOffset;
          let top = scrollTargetTop + offsetTop - buffer;
          window.scrollTo({ top: top, behavior: 'smooth' });
        }
      });
    }
  );
})

import { Controller } from "@hotwired/stimulus"

/*
  検索画面の「募集中の案件のみ表示」チェックボックスのハンドラ
*/
export default class extends Controller {
    connect() {
        console.log("active-job connect")
    }

    // チェックボックスが変わったときに呼ばれる
    activeSearch() {
        console.log("active-job activeSearch")
        const checkbox = this.element.querySelector('input[type="checkbox"]')
        // console.log("checked:", checkbox.checked)
        const form = getForm()
        if (form) {
            // 検索フォームのactiveにチェックボックスの状態を設定してサブミットする。
            const active = form.querySelector('input[name="active"]')
            active.value = checkbox.checked
            form.submit()
        }
    }
}

// フォームを探す。
const getForm = () => {
    // トップ画面：<section#searchSection>
    // 検索画面：<section#registerSection>
    // に検索のためのフォームが配置されているのを取得する。
    const section = document.querySelector("#searchSection") || document.querySelector("#registerSection")
    if (section) {
        const form = section.querySelector("form")
        return form
    }
    return null
}
import { Controller } from "@hotwired/stimulus"

/*
  マイページ　希望条件の登録・変更
*/
export default class extends Controller {
  static targets = ["workTypes"]

  connect() {
    console.log("mypage-preference connect")
    // 画面の初期設定を行う
    this.checkChange()
  }

  // 希望就業形態のチェックボックスが変わったときに呼ばれる
  checkChange() {
    console.log("mypage-preference checkChange")
    const workStyles = this.selectedWorkStyles()
    this.changeDisplay(workStyles, 'freelance', ["業務委託", "派遣", "副業"])
    this.changeDisplay(workStyles, 'subcontract', ["業務委託", "派遣"])
    this.changeDisplay(workStyles, 'sidejob', ["副業"])
    this.changeDisplay(workStyles, 'career', ["正社員"])
  }

  /**
   * 選択された希望就業形態名の一覧を取得する
   */
  selectedWorkStyles() {
    const workTypes = this.workTypesTarget
    // ラベル、チェックボックス等が<li>要素の中にまとめられているので、その一覧を取得
    const checkBoxes = workTypes.querySelectorAll('li')
    const labels = []
    for (let i = 0; i < checkBoxes.length; i++) {
      const c = checkBoxes[i]
      const checked = c.querySelector("input[type='checkbox']").checked
      const label = c.querySelector('span').textContent
      if (checked) {
        labels.push(label)
      }
    }
    return labels
  }

  /**
   * 希望就業形態の選択状態に応じて表示を切り替える
   * 
   * @param {*} workStyles 選択された希望就業形態の名前
   * @param {*} clazz 表示を切り替える要素のクラス名
   * @param {*} namelist 表示対象となる希望就業形態の名前リスト
   */
  changeDisplay(workStyles, clazz, namelist) {
    let found = false
    // namelistの文字列を「含む」希望就業形態が一つでも選択されていたら
    // clazzで指定された要素を表示する。一つも選択されていないときは表示しない。
    for (let i = 0; i < namelist.length; i++) {
      if ((workStyles.filter((s) => s.includes(namelist[i]))).length > 0) {
        found = true
        break
      }
    }
    // clazzで指定された要素の表示をまとめて切り替える
    const selector = `.${clazz}` // クラス名だけで選択
    this.element.querySelectorAll(selector).forEach(elem => elem.style.display = found ? this.elemDisplay(elem) : 'none')
  }

  /**
   * 要素によってdisplay属性に設定する値を判定する。
   * @param {*} elem 
   * @returns 
   */
  elemDisplay(elem) {
    // DIVのときはblock, DLのときはflex。その他の条件が発生したら追加する。
    return elem.tagName === 'DIV' ? 'block' : 'flex'
  }
}

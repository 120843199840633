import $ from "jquery"

/**
* 応募履歴画面 担当者メッセージ展開処理
*/
document.addEventListener("turbo:load", function() {
  $(function() {

    const target = $('.entryHistoryTable .other .message .managerMessage')

    if ($(target).length){

      $(target).each(function() {
        // li.managerMessage要素の高さを取得
        const boxHeight = $(this).innerHeight()
        // span.messageText要素の高さを取得
        const innerTextHeight = $(this).children('p.messageText').height()
        // メッセージの行数
        const lineNum = 2;
        // span.messageText要素内のテキスト1行の高さを取得
        const lineHeight = parseFloat($(this).children('p.messageText').css('line-height'))
        // アコーディオンボタン表示のライン
        const accordionizeHeight = lineHeight * lineNum

        if (innerTextHeight > accordionizeHeight){
          if (innerTextHeight > boxHeight){
            // テキストを一部省略表示する
            $(this).addClass('messageAccordionClamp')
            $(this).removeClass('managerMessage')
          }
        }
      })

      // .moreがクリックされた場合
      $('.entryHistoryTable .other .message .more').on('click', function() {
        // テキストを全文表示する
        $(this).siblings('li.messageAccordionClamp').addClass('messageAccordionOpen')
        $(this).siblings('li.messageAccordionClamp.messageAccordionOpen').removeClass('messageAccordionClamp')
        
      })
    }
  })
})

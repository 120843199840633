import { Controller } from "@hotwired/stimulus"

/*
  親要素のSelectが変更されたときにそれに紐づくSelectの表示・非表示が変更されるControllerです
  @example
    <div data-controller="sync-select">
      <select data-sync-select-target="parent" data-action="sync-select#sync">
        ...
      </select>
      <select data-sync-select-target="child1">
        ...
      </select>
      <select data-sync-select-target="child2">
        ...
      </select>
    </div>
 */

// 表示・非表示を切り替えるクラス名
const hiddenClassName = "non-display"
// バインド先です。もし要素の数を増やしたいときは child3... と増やしてください
const bindtargets = ["parent", "child1", "child2"]

export default class extends Controller {
  static targets = bindtargets

  // HTMLにバインドされたときに実行されるコールバックです
  connect() {
    console.log("sync select connect")
    this.sync()
  }

  // 親のSelect要素が変更されたときに呼び出すアクションです
  // 現在の親のSelect要素をもとに状態を変更します
  sync() {
    console.log("sync")

    // 同期先のSelect要素と親の選択肢の並びが一致していることが前提です
    const selectIndex = this.parentTarget.selectedIndex
    this.childs().forEach((element, index) => {
      if (index == selectIndex) {
        element.classList.remove(hiddenClassName)
        element.disabled = false
      } else {
        element.classList.add(hiddenClassName)
        element.disabled = true
      }
    });
  }

  // 同期先の子Select要素一覧を取得して返します
  childs() {
    // stimulusの命名規則に従って動的に要素の取得をおこなっています
    // @example
    //   child1 => this["child1Target"]
    return(
      bindtargets.slice(1).map((name) => {
        return this[`${name}Target`]
      }).filter(Boolean)
    )
  }
}
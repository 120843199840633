import $ from "jquery"

$(document).on("turbo:load", function(){
  /**
   * 選考状況ボタンを押したときのイベント
   */
  $('[data-id="proposalStatusesModalBtn"]').click(function(){
    //モーダルを開く
    $('[data-id="proposalStatusesModal"]').addClass("open")
  })

  /**
   * 選考状況モーダルのクリアボタンを押したときのイベント
   */
  $('[data-id="proposalStatusesModal"] [data-class="clearBtn"]').click(function(){
    //すべてのチェックボックスのチェックを外す
    $('[data-id="proposalStatusesModal"] input[name="q[proposal_status_eq_any][]"]').prop("checked", false)
  })

  /**
   * 選考状況モーダルの「x」(閉じる)ボタンを押したときのイベント
   */
  $('[data-id="proposalStatusesModal"] [data-class="closeBtn"]').click(function(){
    //すべてのチェックボックスのチェックを外す
    $('[data-id="proposalStatusesModal"] input[name="q[proposal_status_eq_any][]"]').prop("checked", false)

    //検索条件で指定されていた提案ステータスにチェックを付ける（つまりモーダルを開く前の状態に戻す）
    $('[data-id="checkedProposalStatuses"] span').each(function(){
      $('[data-id="proposalStatusesModal"] input[value="'+$(this).text()+'"]').prop("checked", true)
    })

    //モーダルを閉じる
    $('[data-id="proposalStatusesModal"]').removeClass("open")
  })

  /**
   * モーダルを開くボタンを押したときのイベント
   */
  $(document).on("click", `[data-class="modalOpenBtn"]`, function(){
    //モーダルを開く
    var modalId = $(this).data("open-modal-id")
    $(`[data-id="${modalId}"]`).addClass("open")
  });

  /**
   * NG理由プルダウンを変更したときのイベント
   */
  $(document).on("change", `[data-class="ngReason"]`, function(){
    //NG理由が「その他(具体的理由)」のときはフィードバックコメントの入力を必須にする
    if($(this).val() == "Option.U_011437") {
      $(this).parents('[data-class="feedbackForm"]').find('[data-class="feedbackComment"]').attr("required", true)
    } else {
      $(this).parents('[data-class="feedbackForm"]').find('[data-class="feedbackComment"]').attr("required", false)
    }
  });
})

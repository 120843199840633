import $ from "jquery"

$(document).ready(() => {
  /**
   * SP版ヘッダーのMENUボタンを押した時のイベント
   */
  $(document).on("click", "#headNav .openBtn", (event) => {
    const openBtn = $(event.currentTarget)

    //ヘッダーメニューが開かれた状態であれば閉じる
    //閉じられた状態であれば開く
    if(openBtn.hasClass('open')) {
      openBtn.removeClass('open')
    } else {
      openBtn.addClass('open')
    }
  })

  /**
   * SP版ヘッダーメニュー内の閉じるボタンを押した時のイベント
   */
  $(document).on("click", "#headNav .close", () => {
    const openBtn = $("#headNav .openBtn")
    openBtn.removeClass('open')
  })
})

import { Controller } from "@hotwired/stimulus"

/**
 * プロフィールの確認・更新画面の特定のフォームを制御するControllerです
 * */
export default class extends Controller {
  // HTMLにバインドされたときに実行されるコールバックです
  connect() {
    console.log("connect mypage profile")
    this.__viewControll()
  }

  /**
   * ラジオボタンの値が変更されたときに呼び出すアクションです
   */
  onChangeRadioButton() {
    const elements = document.querySelectorAll(".attachmentBlock")
    const selected_code = this.__checkNationalityCode()

    // 「国籍: 日本」の場合
    if (selected_code == "Option.U_Japan") {
      elements.forEach((element) => {
        // フォーム非表示
        element.classList.add("hidden")
      })
    } else {
      elements.forEach((element) => {
        // フォーム表示
        element.classList.remove("hidden")
      })
    }
  }

  /**
   * 現在選択されている国籍コードを確認するアクションです
   */
  __viewControll() {
    const selected_code = this.__checkNationalityCode()

    // 「国籍: 日本」の場合
    if (selected_code == "Option.U_Japan") {
      const elements = document.querySelectorAll(".attachmentBlock")

      elements.forEach((element) => {
        // フォーム非表示
        element.classList.add("hidden")
      })
    }
  }

  /**
   * 現在選択されている国籍コードを確認するアクションです
   */
  __checkNationalityCode() {
    let selected_code
    const radios = document.querySelectorAll(".nationalityCodeOption")
    radios.forEach((radio) => {
      if (radio.checked) {
        selected_code = radio.value
      }
    })

    return selected_code
  }
}
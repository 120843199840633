import $ from "jquery"

/**
 * Mypage::Recommends::AcceptButtonComponentの
 * 応募するボタンと辞退ボタン押下後のモーダル処理に関わるjsです
 */
$(document).ready(() => {
  /**
   * 一覧の応募するボタンを押したときのイベント
   */
  $(document).on('click', `[data-id="recommendsBtnEntry"]`, function(event){
    const jobTitle = $(event.currentTarget).data("jobTitle")
    const careerJob = $(event.currentTarget).data("careerJob") // 文字列からbooleanに変換される
    const acceptUrl = $(event.currentTarget).data("acceptUrl")
    const modalId = $(event.currentTarget).data("open-modal")
    // 案件タイトルを書き換え
    $(`[data-id="recommendsAcceptModalAcceptJobTitle"]`).text(jobTitle)
    // 応募するボタンのURLを書き換え
    $(`[data-id="recommendsAcceptModalAcceptUrl"]`).prop('href', acceptUrl)
    // モーダル表示クラスを追加
    $(`[data-id="${modalId}"`).addClass("open")
    // モーダルの種類に応じて文言を変更する
    if (modalId === "recommendsAcceptModal") {
      // 応募ダイアログの文言をキャリアとフリーランスで切り替える
      if (careerJob) {
        $(`[data-id="recommendsAcceptModalAcceptMessage"]`).text("こちらの求人に応募します")        
      } else {
        $(`[data-id="recommendsAcceptModalAcceptMessage"]`).text("こちらの案件に応募します")        
      }
    }
  });

  /**
   * 一覧の辞退するボタンを押したときのイベント
   */
  $(document).on('click', `[data-id="recommendsBtnGray"]`, function(event){
    const jobTitle = $(event.currentTarget).data("jobTitle")
    const careerJob = $(event.currentTarget).data("careerJob") // 文字列からbooleanに変換される
    const declineUrl = $(event.currentTarget).data("declineUrl")
    const modalId = $(event.currentTarget).data("open-modal")
    // 案件タイトルを書き換え
    $(`[data-id="recommendsAcceptModalDeclineJobTitle"]`).text(jobTitle)
    // 辞退するボタンのURLを書き換え
    $(`[data-id="recommendsAcceptModalDeclineUrl"]`).prop('href', declineUrl)
    // モーダル表示クラスを追加
    $(`[data-id="${modalId}"`).addClass("open")
    // モーダルの種類に応じて文言を変更する
    if (modalId === "recommendsDeclineModal") {
      // 辞退ダイアログの文言をキャリアとフリーランスで切り替える
      if (careerJob) {
        $(`[data-id="recommendsAcceptModalDeclineMessage"]`).text("こちらの求人を辞退しますが間違いないですか？")        
      } else {
        $(`[data-id="recommendsAcceptModalDeclineMessage"]`).text("こちらの案件を辞退しますが間違いないですか？")        
      }
    }
  });

  /**
   * 応募後・辞退後の閉じるボタン、モーダル右上の「×」ボタンを押したときのイベント
   */
  $(document).on("click",`[data-class="recommendsAcceptModalClose"]`, function(){
    $(`[data-class="recommendsAcceptModalCommon"]`).removeClass("open")
    $(`[data-class="recommendsDeclineModal"]`).removeClass("open")
  });
})

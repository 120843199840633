import { Controller } from "@hotwired/stimulus"
import { ApiClient } from "../../utils/api_client"

/**
 * @param { job: Object, openModalIndex: number, title: string}
 */
const defaultVal = {
  /**
   * @param {Object}
   */
  job: {},
  /**
   * 値はマイナス値を設定してください
   * @param {number}
   */
   openModalIndex: -1,
  /**
   * デフォルトでは名前の変更ができなくても違和感がない値にしています
   * @param {string}
   */
   title: "本案件",
}

/**
 * @param {string} モーダルを開閉時に付与・削除するクラス名
*/
const openModalClass = "open"
/**
 * @param {string[]} バインド先
*/
const controllTargets = ["title", "content"]
/**
 * @param {Object} バインド先コンテンツの番号
*/
const contentIndex = { 
  webEntry: 0, // web応募ダイアログ
  entryCompleted: 1, // 応募完了ダイアログ
  needLogin: 2, // ログイン要求ダイアログ
  error: 3, // エラーダイアログ
  job_applied: 4, // 重複応募エラーダイアログ
  need_start_freelance: 5, // Remoguフリーランス利用開始要求ダイアログ
  need_start_career: 6 // Remoguキャリア利用開始要求ダイアログ
}

export default class extends Controller {
  /**
   * title: 案件タイトル(複数)
   * content: モーダル(複数)
  */
  static targets = controllTargets

  static values = {
    isLogined: Boolean
  }

  /** クラスがインスタンス化されたタイミングで呼ばれるコールバックです */
  initialize() {
    this.job = defaultVal.job
    this.openModalIndex = defaultVal.openModalIndex
    this.requestPath = ""
  }

  /** HTMLにバインドされるときに実行されるコールバックです */
  connect() {
    console.log("jobs entry form connect")

    this.update()
  }

  /**
   * 申し込みボタンが押されたときにフォームダイアログを開きます
   * 押された判定はカスタムイベントで通知をしてください
   * @param {CustomEvent<{ detail: { jobId: number, jobTitle: string }}>} event 
   */
  open(event) {
    console.log("open()")
    // 「応募する」ボタンで発生するイベントのパラメータを取得し、以下の判定で使う。
    this.job = event.detail.job
    this.candidate = event.detail.candidate
    this.requestPath = event.detail.requestPath
    // jobとcandidateからopenModalIndex（表示するモーダルのインデックス）を決定する。
    if (this.candidate.enabled_career_service && !this.candidate.enabled_freelance_service) {
      // ユーザの利用サービスがキャリアのみ
      if (this.job.career_job) {
        this.openModalIndex = contentIndex.webEntry
      } else {
        this.openModalIndex = contentIndex.need_start_freelance
      }
    } else if (!this.candidate.enabled_career_service && this.candidate.enabled_freelance_service) {
      // ユーザの利用サービスがフリーランスのみ
      if (this.job.career_job) {
        this.openModalIndex = contentIndex.need_start_career
      } else {
        this.openModalIndex = contentIndex.webEntry
      }
    } else {
      // ユーザの利用サービスがキャリアとフリーランス
      this.openModalIndex = contentIndex.webEntry
    }

    this.update()
  }

  /**
   * web申し込みのリクエストを送信します
   */
  entry() {
    console.log("entry")

    const client = ApiClient()
    /** 注意: requestPath を抽象化し、押したボタンからパラメータを受け取るようにしています */
    client.post(this.requestPath).then((json) => {
      if(json.status) {
        /** ボタン側の表示を変更するため通知を送る */
        window.dispatchEvent(new CustomEvent(
          "formCompleted",
          { detail: { job: this.job } }
        ))

        if(json.selectionProcess == "job_applied") {
          this.openModalIndex = contentIndex.job_applied
        } else {
          this.openModalIndex = contentIndex.entryCompleted
        }
        this.update()
      } else {
        this.openModalIndex = contentIndex.error
        this.update()
      }
    })
  }

  /**
   * 申し込みフォームが綴じられたときに呼び出す関数です
   */
  close() {
    this.job = defaultVal.job
    this.openModalIndex = defaultVal.openModalIndex
    this.requestPath = ""

    this.update()
  }

  /**
   * 現在の状態に合わせて画面を更新します
   */
  update() {
    this.changeText()
    this.changeModal()
  }

  // --- private ---

  /** モーダルの案件名の表示部分の文字列を変更する */
  changeText() {
    if (this.job == defaultVal.job) {
      // 案件名を戻す
      this.titleTargets.forEach((elem) => {
        elem.textContent = defaultVal.title
      })
    } else {
      // 案件名を設定する
      this.titleTargets.forEach((elem) => {
        elem.textContent = this.job.title
      })
    }
    // モーダルの種類に応じて文言を変更する
    if (this.openModalIndex === contentIndex.webEntry) {
      // 応募ダイアログの文言をキャリアとフリーランスで切り替える
      const content = this.contentTargets[this.openModalIndex]
      const contentDesc = content.querySelector("dd.contentDesc")
      if (this.job.career_job) {
        contentDesc.textContent = "こちらの求人に応募します"
      } else {
        contentDesc.textContent = "こちらの案件に応募します"
      }
    }
  }

  /** 表示するモーダルを変更する */
  changeModal() {
    // this.contentTargets[openModalIndex]を表示、それ以外を非表示にする。
    if (this.isLoginedValue) {
      this.contentTargets.forEach((element, index) => {
        // メモ: デフォルト値のときは常にfalseになるため全部閉じる
        if (index === this.openModalIndex) {
          element.classList.add(openModalClass)
        } else {
          element.classList.remove(openModalClass)
        }
      })
      return
    }

    // ログインをしていないときに開こうとしていたら会員登録要求モーダルを開く
    const modal = this.contentTargets[contentIndex.needLogin]
    if (this.openModalIndex != defaultVal.openModalIndex) {
      modal.classList.add(openModalClass)
    } else {
      modal.classList.remove(openModalClass)
    }
  }
}

import React from "react"

type Props = {
  message: string
}
export const ReactSample: React.FC<Props> = ({ message }) => {
  return(
    <>
      <h4>ReactSampleのコンポーネントを表示しています</h4>
      <p>~ app/javascript/components/ReactSample.tsx ~</p>
      <p>message: {message}</p>
    </>
  )
}

export default ReactSample
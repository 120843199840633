/**
 * 文字列を中央でトランケートして返します
 * @example
 *   長いファイル名長いファイル名長いファイル名.xlsx #=> 長いファイル名長い...名.xlsx
 */
export const truncateMiddle = (fullStr, strLen, separator) => {
  if (fullStr.length <= strLen) return fullStr;

  separator = separator || '...';

  var sepLen = separator.length,
      charsToShow = strLen - sepLen,
      frontChars = Math.ceil(charsToShow/2),
      backChars = Math.floor(charsToShow/2);

  return fullStr.substr(0, frontChars) + 
         separator + 
         fullStr.substr(fullStr.length - backChars);
};
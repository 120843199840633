import { Controller } from "@hotwired/stimulus"

/*
  Form要素にバインドすることでいろいろなアクションを設定できるようにするために作成したControllerです
  @example
    <form data-controller="form">
      <button type="button" data-action="form#clear">クリア</button>
    </form>
 */

export default class extends Controller {
  connect() {
    console.log("form connect")
  }

  // フォームの要素を全て初期値に戻す処理を行います
  clear() {
    console.log("clear")

    const form = this.targets.element

    // reset() では初期値に戻すだけなので明示的に各要素を空にする
    //   必要に応じて処理を追加してください
    form.querySelectorAll("input").forEach((element) => {
      switch(element.type) {
        case "checkbox":
          element.checked = false
          break
        case "text":
          element.value = ""
          break
      }
    })

    form.querySelectorAll("select").forEach((element) => {
      element.selectedIndex = 0
    })

    form.querySelectorAll(".selectNum").forEach((element) => {
      element.textContent = ""
    })

    // クリア処理に応じて値を変更したい箇所のために通知を送る
    const event = new CustomEvent('form-clear')
    window.dispatchEvent(event);
  }
}
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("receive-recommend-mail-toggle connect")
  }

  /**
   * トグルボタンが切り替えられた際、ON/OFFに対応する値をupdateアクションへ送信する
   */
  submit(event) {
    const form = event.target.parentElement
    const button = form.querySelector('input[type="submit"]')
    button.click()
  }
}

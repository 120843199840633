import { Controller } from "@hotwired/stimulus"
import { truncateMiddle } from "../utils/str_truncate"
import { validMaxSizeM } from "../utils/file_validator"

/**
 * ファイルのアップロードがかかわるFormに利用するControllerです
 * アップロードするファイル名を設定したり、ボタンがあれば非活性・活性をコントロールします
 * */
export default class extends Controller {
  static targets = ["fileName", "submit"]
  // HTMLにバインドされたときに実行されるコールバックです
  connect() {
    console.log("connect file set")
    this.__setFileName(null)
    this.__setSubmitState(null)
  }

  /**
   * ファイルが設定されたときに呼び出すアクションです
   */
  onChangeFile(event) {
    // 実装時点ではファイルは1つしか設定しない想定です
    const file = event.target.files[0]

    if (validMaxSizeM(file, 2)) {
      this.__setFileName(file)
      this.__setSubmitState(file)  
    } else {
      alert("ファイルは2MB以下で設定してください")
    }
  }

  /**
   * フォームに設定したファイル名を表示させます
   * もしファイルがないときはデフォルトの文字列を表示させます
   */
  __setFileName(file) {
    // 名前の表示部分は value 属性を表示させる(例 input[type="text"])DOMにしてください
    if (file) {
      // ファイル名が長いときは拡張子を出したいので中央をトランケートさせています。文字数はspデザインでも入り切る文字数です
      this.fileNameTarget.value = truncateMiddle(file.name, 19, "...")
    } else {
      this.fileNameTarget.value = "選択されていません"
    }
  }

  __setSubmitState(file) {
    // ボタンがなければ処理をしない
    if (!this.submitTarget) { return }

    if (file) {
      this.submitTarget.disabled = false
    } else {
      this.submitTarget.disabled = true
    }
  }
}
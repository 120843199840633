import { Controller } from "@hotwired/stimulus"

/**
 * HTMLにコネクトされるタイミングでその要素まで画面をスクロールするコントローラーです
 *
 * ## 経緯
 * * turbo_frame_tag で一部の要素を書き換えた際にページのトップにスクロールをしてほしいタイミングがあります
 *   * 例: 入力Formのバリデーションエラーによる再レンダリング時など
 * * 本来であれば turbo_frame_tag に autoscroll 属性を付与することで自動スクロールがされるようですが、
 *   少なくともRemoguでは希望条件Formでは動作がしませんでした
 *   * 原因は不明ですがPOST系リクエストでは動かないかもしれません(参考記事はどれもGETリクエストが例だったため)
 *   * 参考: https://turbo.hotwired.dev/reference/frames#html-attributes (公式ドキュメント)
 * * そのため本Controllerを利用することで、HOTWireによる書き換え時に要素のTOPにスクロールを行うようにしました。
 * ## 利用方法
 *   * app/views/mypage/preferences/edit.turbo_stream.erb を参照ください
 */
export default class extends Controller {
  connect() {
    console.log("scroll top connect")
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
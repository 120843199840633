import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("use-start-button connect")
  }

  /**
   * Remoguのサービス利用開始時のアンケートダイアログを開く処理
   */
  openDialog(event) {
    const openModalName = event.params.modalName
    const dialog = document.querySelector(`[data-id=${openModalName}]`)

    // ここで一度入力チェック
    if (openModalName == "useStartFreelanceModal") {
      this.requireWorkStyle()
      this.requireConditions()
      this.requireStartAvailableDate()
    } else if (openModalName == "useStartCareerModal") {
      this.requireCurrentCompany()
      this.requirePossibleChangeOfCareer()
      this.requireCurrentAnnualSalary()
      this.requireAnnualSalary()
      this.requireAddress()
    }

    dialog.classList.add("open")
  }

  /**
   * 希望就業形態が入力されているかチェックする処理
   */
  requireWorkStyle() {
    const currentPage = document.querySelector('[data-require-check-id="workStyle"]')
    const checkBoxes = currentPage.querySelectorAll('input[type="checkbox"]')
    const nextButton = currentPage.querySelector('li[data-class="nextButton"]')
    let anyChecked = false

    checkBoxes.forEach(ch => anyChecked ||= ch.checked)
    if (anyChecked) {
      nextButton.classList.remove("disabled")
    } else {
      nextButton.classList.add("disabled")
    }
  }

  /**
   * 就業条件が入力されているかチェックする処理
   */
  requireConditions() {
    const currentPage = document.querySelector('[data-require-check-id="conditions"]')
    const checkBoxes = currentPage.querySelectorAll('input[type="checkbox"]')
    const nextButton = currentPage.querySelector('li[data-class="nextButton"]')
    let anyChecked = false

    checkBoxes.forEach(ch => anyChecked ||= ch.checked)
    if (anyChecked) {
      nextButton.classList.remove("disabled")
    } else {
      nextButton.classList.add("disabled")
    }
  }

  // 稼働日数は任意項目のため、入力チェックなし

  /**
   * 稼働可能時期が入力されているかチェックする処理
   */
  requireStartAvailableDate() {
    const currentPage = document.querySelector('[data-require-check-id="startAvailableDate"]')
    const year = currentPage.querySelector("select.customYear").value
    const month = currentPage.querySelector("select.month").value
    const day = currentPage.querySelector("select.day").value
    const submitButton = currentPage.querySelector('button[type="submit"]')

    // 年月日全て選択されているかチェック
    if (!year || !month || !day) {
      submitButton.disabled = true
    } else {
      // 存在しない日付でないかをチェック
      var date = new Date(parseInt(year), parseInt(month) - 1 , parseInt(day))
      submitButton.disabled = !(date.getFullYear() == parseInt(year) && date.getMonth() == parseInt(month) - 1 && date.getDate() == parseInt(day))
    }
  }

  /**
   * 現在の勤め先が入力されているかチェックする処理
   */
  requireCurrentCompany() {
    const currentPage = document.querySelector('[data-require-check-id="currentCompanyName"]')
    const textBox = currentPage.querySelector('input[type="text"]')
    const nextButton = currentPage.querySelector('li[data-class="nextButton"]')

    if (textBox.value) {
      nextButton.classList.remove("disabled")
    } else {
      nextButton.classList.add("disabled")
    }
  }

  /**
   * 転職可能時期が入力されているかチェックする処理
   */
  requirePossibleChangeOfCareer() {
    const currentPage = document.querySelector('[data-require-check-id="possibleChangeOfName"]')
    const radioButtons = currentPage.querySelectorAll('input[type="radio"]')
    const nextButton = currentPage.querySelector('li[data-class="nextButton"]')
    let anyChecked = false

    radioButtons.forEach(ra => anyChecked ||= ra.checked)
    if (anyChecked) {
      nextButton.classList.remove("disabled")
    } else {
      nextButton.classList.add("disabled")
    }
  }

  /**
   * 現年収が入力されているかチェックする処理
   */
  requireCurrentAnnualSalary() {
    const currentPage = document.querySelector('[data-require-check-id="currentAnnualSalary"]')
    const numberBox = currentPage.querySelector('input[type="number"]')
    const nextButton = currentPage.querySelector('li[data-class="nextButton"]')

    if (numberBox.value > 0) {
      nextButton.classList.remove("disabled")
    } else {
      nextButton.classList.add("disabled")
    }
  }

  /**
   * 希望年収が入力されているかチェックする処理
   */
  requireAnnualSalary() {
    const currentPage = document.querySelector('[data-require-check-id="annualSalary"]')
    const numberBox = currentPage.querySelector('input[type="number"]')
    const nextButton = currentPage.querySelector('li[data-class="nextButton"]')

    if (numberBox.value > 0) {
      nextButton.classList.remove("disabled")
    } else {
      nextButton.classList.add("disabled")
    }
  }

  /**
   * 住所の詳細が入力されているかチェックする処理
   */
  requireAddress() {
    const currentPage = document.querySelector('[data-require-check-id="address"]')
    const textBox = currentPage.querySelector('input[type="text"]')
    const submitButton = currentPage.querySelector('button[type="submit"]')

    if (textBox.value) {
      submitButton.disabled = false
    } else {
      submitButton.disabled = true
    }
  }

  /**
   * 「上記の内容で次へ進む」ボタンクリック時の処理
   */
  nextButton(event) {
    const clickButton = event.target.parentElement
    const currentPage = document.querySelector(`[data-id=${clickButton.dataset.currentModalBody}]`)
    const nextPage = document.querySelector(`[data-id=${clickButton.dataset.nextModalBody}]`)

    currentPage.classList.add("non-display")
    nextPage.classList.remove("non-display")
  }

  /**
   * 「1つ前に戻る」ボタンクリック時の処理
   */
  prevButton(event) {
    const clickButton = event.target.parentElement
    const currentPage = document.querySelector(`[data-id=${clickButton.dataset.currentModalBody}]`)
    const prevPage = document.querySelector(`[data-id=${clickButton.dataset.prevModalBody}]`)

    currentPage.classList.add("non-display")
    prevPage.classList.remove("non-display")
  }

  /**
   * 「×」ボタンクリック時の処理
   */
  closeDialog(event) {
    const openModalName = event.target.dataset.currentModal
    const dialog = document.querySelector(`[data-id=${openModalName}]`)
    dialog.classList.remove("open")
  }
}

import { Controller } from "@hotwired/stimulus"
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'

/**
 * tippy.jsによるツールチップを起動するコントローラーです
 *   通常の tippy.js の起動方法では Turbo Stream 時に発火しないため stimulus から起動できるようにしています
 * */
export default class extends Controller {
  // HTMLにバインドされたときに実行されるコールバックです
  connect() {
    console.log("connect tooltip")

    this.setting()
  }

  /** ツールチップを設定します */
  setting() {
    // 画面幅を取得する
    const windowSize = $(window).width()
  
    if (windowSize < 769) {
      // SP版レイアウトの場合、ツールチップをアイコン上側に表示
      tippy(this.element, {
        placement: 'top',
        animation: 'sift-away',
        duration: 0,
        arrow: true,
        theme: 'white-top'
      })
    } else {
      // PC版レイアウトの場合、ツールチップをアイコン右側に表示
      tippy(this.element, {
        placement: 'right',
        animation: 'sift-away',
        duration: 0,
        arrow: true,
        theme: 'white-right'
      })
    }
  }    
}
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
// stimulusを利用するため
import "controllers"
import { Turbo } from "@hotwired/turbo"

// HACK: 個別のscriptは必要なページごとで読み込むようにしたほうが良い
require("../scripts/footers/new_jobs")
require("../scripts/history/accordion")
require("../scripts/recommends/modal")
require("../scripts/document_proposals/modal")
require("../scripts/mypage/skill_sheet_scroll")
require("../scripts/common/header_menu")
require("../scripts/job/modal")
require("../scripts/common/delete_old_cookie")

Rails.start()
ActiveStorage.start()
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

require('jquery')


import { Controller } from "@hotwired/stimulus"

// 表示・非表示を切り替えるクラス名
const hiddenClassName = "non-display"

/** モーダルの開閉処理を行えるコントローラーです  */ 
export default class extends Controller {
  /**
   * content: モーダルで表示する内容を指定してください
  */
  static targets = ["content"]

  /** クラスがインスタンス化されたタイミングで呼ばれるコールバックです */
  initialize() {
    /**
     * 現在モーダルが開いているかを管理する状態フラグです
     * @type {boolean}
     */
     this._isOpen = false
  }

  // HTMLにバインドされたときに実行されるコールバックです
  connect() {
    console.log("connect modal")

    this.update()
  }

  /** モーダルを開く状態にします */
  open() {
    this._isOpen = true

    this.update()
  }

  /** モーダルを閉じる状態にします */
  close() {
    this._isOpen = false

    this.update()
  }

  /** 現在のモーダルの状態フラグに合わせて画面を更新します */
  update() {
    if (this._isOpen) {
      this.contentTarget.classList.remove(hiddenClassName)
    } else {
      this.contentTarget.classList.add(hiddenClassName)
    }
  }
}
import { Controller } from "@hotwired/stimulus"

/*
  アコーディオンの制御を伴うテキスト要素を扱います
  accordionBoxのクラスが指定された要素に、 
  clampクラスを指定することでアコーディオンを閉じた状態になります
  @example
    <dd data-controller="accordion-box">
      <div class="accordionBox">
        <div class="inner">
          テキストテキストテキストテキストテキスト
        </div>
      </div>
      <div class="more" data-action="click->accordion-box#open"></div>
    </dd>
 */
export default class extends Controller {
  connect() {
    console.log("accordion-box connect")
    const textElement = getTextElement(this.targets.element)
    const innerElement = getInnerElement(this.targets.element)
    

    const textHeight = getInnerHeight(textElement)
    const innerTextHeight = getInnerHeight(innerElement)
    if (innerTextHeight > textHeight) {
      textElement.classList.add("clamp")
    }
  }

  // 開くボタンクリック時にテキストを全文表示する
  // 再び閉じるボタンは用意しない
  open() {
    console.log("accordion-box open")
    const textElement = getTextElement(this.targets.element)
    textElement.classList.remove("accordionBox")
    textElement.classList.remove("clamp")
  }
}

const getTextElement = (element) => {
  return element.querySelector(".accordionBox")
}

const getInnerElement = (element) => {
  return element.querySelector(".inner")
}

// 内部要素の全文表示した際の高さを取得する
const getInnerHeight = (element) => {
  const computed = getComputedStyle(element)
  const padding = parseInt(computed.paddingTop) + parseInt(computed.paddingBottom)
  return element.clientHeight - padding
}
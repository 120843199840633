import { Controller } from "@hotwired/stimulus"

/** 検索Formのチェックボックスの処理を行うコントローラーです  */ 
export default class extends Controller {
  /** 
   * checkNum: チェックが入っている項目数を表示させるDOMに設定してください
   */
  static targets = ["checkNum"]

  /** HTMLにバインドされたときに実行されるコールバックです  */
  connect() {
    console.log("search check box connect")

    this.update()
  }

  /** 
   * チェックボックスのモーダルが開かれたときに呼び出すメソッドです
   * チェックボックス一覧を開いたときの状態保持することで、モーダルを閉じたときに元に戻せるようにしています
   */
  open() {
    this.checkStateList = []
    this.checkBoxs().forEach((element) => {
      this.checkStateList.push(element.checked)
    })

    this.update()
  }

  /** 
   * チェックボックスの状態をopenが呼び出された状態のときに戻します
   * チェックした内容を破棄したいタイミングで呼び出してください
   */
  restore() {
    this.checkBoxs().forEach((element, index) => {
      const state = this.checkStateList[index] || false
      element.checked = state
    })

    this.update()
  }

  /** 
   * チェックボックスのモーダルを閉じるときに呼び出すメソッドです
   * 最終的なチェック状態をもとに画面を更新します
   */
  close() {
    this.update()
  }

  /** 
   * 全てのチェックボックスのチェックを外します
   * クリアボタンを押したときなどに呼び出してください
   */
  clear() {
    this.checkBoxs().forEach((element) => {
      element.checked = false
    })

    this.update()
  }

  // --- private ---

  /** 
   * 画面を更新する共通的な処理です
   * 基本的に毎回呼び出してください
   */
  update() {
    this.updateCheckNum()
  }

  /** 
   * 現在チェっっくされているチェックボックスの数で、テキストを更新します
   */
  updateCheckNum() {
    const totalNum = this.checkTotalNum()
    if (totalNum == 0) {
      this.checkNumTarget.textContent = ""
    } else {
      this.checkNumTarget.textContent = `${totalNum}件選択中`
    }
  }

  /** 
   * 現在チェっっくされているチェックボックスの数を返します
   * @return {number}
   */
  checkTotalNum() {
    var count = 0
    this.checkBoxs().forEach((element) => {
      if (element.checked) {
        count += 1
      }
    })

    return count
  }

  /** 
   * バインドされているチェックボックスエレメントを一覧で返します
   * @return {Array<Element>}
   */
  checkBoxs() {
    return this.targets.element.querySelectorAll('input[type="checkbox"]') || []
  }
}
import { Controller } from "@hotwired/stimulus"
import { truncateMiddle } from "../utils/str_truncate"
import { validMaxSizeM } from "../utils/file_validator"

/**
 * プロフィールの確認・更新画面のファイルアップロードFormに利用するControllerです
 * アップロードするファイル名を設定します
 * */
export default class extends Controller {
  static targets = ["coeName"]
  // HTMLにバインドされたときに実行されるコールバックです
  connect() {
    console.log("connect profile file set")
    this.__setFileName(null)
  }

  /**
   * プロフィールの確認・更新画面でファイルが設定されたときに呼び出すアクションです
   */
  onChangeFile(event) {
    // 実装時点ではファイルは1つしか設定しない想定です
    const file = event.target.files[0]

    if (validMaxSizeM(file, 10)) {
      this.__setFileName(file)
    } else {
      alert("ファイルは10MB以下で設定してください")
    }
  }

  /**
   * フォームに設定したファイル名を表示させます
   * もしファイルがないときはデフォルトの文字列を表示させます
   */
  __setFileName(file) {
    // 名前の表示部分は value 属性を表示させる(例 input[type="text"])DOMにしてください
    if (file) {
      // ファイル名が長いときは拡張子を出したいので中央をトランケートさせています。文字数はspデザインでも入り切る文字数です
      this.coeNameTarget.value = truncateMiddle(file.name, 19, "...")
    } else {
      this.coeNameTarget.value = "選択されていません"
    }
  }
}
import { post as railsPost } from '@rails/request.js'

export const ApiClient = () => {
  /**
   * POSTリクエストをAjaxで送信します。その後戻り値のjsonオブジェクトをPromiseで返します
   * @param {string} path
   * @param {Object} params 
   * @returns {Promise}
   */
  const post = (path: string, params: Object) => {
    return(
      railsPost(path, { body: JSON.stringify(params) })
        .then((res) => {
          return res.json
        })
    )
  }

  return { post }
}
import { Controller } from "@hotwired/stimulus"
import { createLoadingElement } from "../utils/elements"

/**
 * ローディングのアイコンを表示するためのControllerです
 */
export default class extends Controller {
  connect() {
    console.log("loading connect")
  }

  /**
   * もっと見るボタンを非表示にして、
   * ローディングアイコンを表示します
   */
  load() {
    const elem = this.targets.element
    elem.parentNode.insertBefore(createLoadingElement(), elem.nextSibling)
    elem.style.display ="none"
  }
}
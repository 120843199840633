import $ from "jquery"

/**
 * 応募するボタン(web応募)とボタン押下後のモーダル処理に関わるjsです
 */
document.addEventListener("turbo:load", function() {
/**
 * 応募するボタン(Jobs::EntryButtonComponent)を押したときのイベント
 */
  $(`[data-id="jobBtnEntry"]`).click((event) => {
    const jobTitle = $(event.currentTarget).data("jobTitle")
    const careerJob = $(event.currentTarget).data("careerJob") // 文字列からbooleanに変換される
    const entryUrl = $(event.currentTarget).data("entryUrl")
    const modalId = $(event.currentTarget).data("open-modal")
    // 案件タイトルを書き換え
    $(`[data-id="jobEntryModalEntryJobTitle"]`).text(jobTitle)
    // 応募するボタンのURLを書き換え
    $(`[data-id="jobEntryModalEntryUrl"]`).prop('href', entryUrl)
    // モーダル表示クラスを追加
    $(`[data-id="${modalId}"`).addClass("open")
    // モーダルの種類に応じて文言を変更する
    if (modalId === "jobEntryModal") {
      // 応募ダイアログの文言をキャリアとフリーランスで切り替える
      if (careerJob) {
        $(`[data-id="jobEntryModalEntryMessage"]`).text("こちらの求人に応募します")        
      } else {
        $(`[data-id="jobEntryModalEntryMessage"]`).text("こちらの案件に応募します")        
      }
    }
  })

/**
 * 応募後の閉じるボタン、モーダル右上の「×」ボタンを押したときのイベント
 */
  $("body").on("click",`[data-class="jobEntryModalClose"]`, (event) => {
    $(`[data-class="jobEntryModalCommon"]`).removeClass("open")
  })
})

import { Controller } from "@hotwired/stimulus"

/*
  SP版の続きを読むを制御します
  spMoreクラスがつけられた要素の親要素に、openクラスをadd/removeします
  @example
    <li class="open" data-controller="sp-more">
      <div>....</div>   
      <div class="spMore" data-action="click->sp-more#open"><span></span></div>
    </li>
 */
export default class extends Controller {
  connect() {
    console.log("sp-more connect")
  }

  toggle() {
    console.log("sp-more open")
    if (this.targets.element.classList.contains("open")) {
      this.targets.element.classList.remove("open")
    } else {
      this.targets.element.classList.add("open")
    }
    
  }
}
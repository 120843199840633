import { Controller } from "@hotwired/stimulus"

/*
  条件確認ダイアログのハンドラ
*/
export default class extends Controller {
  static targets = [
    "workStyle",
    "condition",
    "startAvailableDate",
    "careerBody1",
    "careerBody2",
    "careerBody3",
    "careerBody4",
    "careerBody5"
  ]
  connect() {
    console.log("survey_modal connect")
  }

  // 希望条件登録後に希望就業形態と利用サービスに齟齬があった場合に、アンケートダイアログを手動で開くための処理
  open(event) {
    const openModalName = event.params.modalName
    const dialog = document.querySelector(`[data-id=${openModalName}]`)
    dialog.classList.add("open")
  }

  // 希望就業形態のチェックボックスが変わったとき
  workStyleCheck() {
    const checkBoxes = this.workStyleTarget.querySelectorAll('input[type="checkbox"]')
    const nextButton = this.workStyleTarget.querySelector('li[data-class="nextButton"]')
    let anyChecked = false
    checkBoxes.forEach(ch => anyChecked ||= ch.checked)
    if (anyChecked) {
      nextButton.classList.remove("disabled")
    } else {
      nextButton.classList.add("disabled")
    }
  }

  // 就業条件のチェックボックスが変わったとき
  conditionCheck() {
    const checkBoxes = this.conditionTarget.querySelectorAll('input[type="checkbox"')
    const nextButton = this.conditionTarget.querySelector('li[data-class="nextButton"]')
    let anyChecked = false
    checkBoxes.forEach(ch => anyChecked ||= ch.checked)
    if (anyChecked) {
      nextButton.classList.remove("disabled")
    } else {
      nextButton.classList.add("disabled")
    }
  }

  // 稼働日数は任意項目のためチェックしない

  // 稼働可能時期のチェック
  startAvailableDateCheck() {
    const year = this.startAvailableDateTarget.querySelector("select.customYear").value
    const month = this.startAvailableDateTarget.querySelector("select.month").value
    const day = this.startAvailableDateTarget.querySelector("select.day").value
    const submitButton = this.startAvailableDateTarget.querySelector('button[type="submit"]')

    // 年月日全て選択されているかチェック
    if (!year || !month || !day) {
      submitButton.disabled = true
    } else {
      // 存在しない日付でないかをチェック
      var date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day))
      submitButton.disabled = !(date.getFullYear() == parseInt(year) && date.getMonth() == parseInt(month) - 1 && date.getDate() == parseInt(day))
    }
  }

  // 現在お勤めの企業名が変更されたとき
  currentCompanyNameChange() {
    const currentCompanyName = this.careerBody1Target.querySelector('input[type="text"')
    const nextButton = this.careerBody1Target.querySelector('li[data-class="nextButton"]')
    if (currentCompanyName.value) {
      nextButton.classList.remove("disabled")
    } else {
      nextButton.classList.add("disabled")
    }
  }

  // 転職可能時期が変更されたとき
  possibleChangeOfCareerChange() {
    const radioButtons = this.careerBody2Target.querySelectorAll('input[type="radio"')
    const nextButton = this.careerBody2Target.querySelector('li[data-class="nextButton"]')
    let anyChecked = false
    radioButtons.forEach(ra => anyChecked ||= ra.checked)
    if (anyChecked) {
      nextButton.classList.remove("disabled")
    } else {
      nextButton.classList.add("disabled")
    }
  }

  // 現年収が変更されたとき
  currentAnnualSalaryChange() {
    const currentAnnualSalary = this.careerBody3Target.querySelector('input[type="number"')
    const nextButton = this.careerBody3Target.querySelector('li[data-class="nextButton"]')
    if (parseInt(currentAnnualSalary.value) > 0) {
      nextButton.classList.remove("disabled")
    } else {
      nextButton.classList.add("disabled")
    }
  }

  // 希望年収が変更されたとき
  annualSalaryChange() {
    const annualSalary = this.careerBody4Target.querySelector('input[type="number"')
    const nextButton = this.careerBody4Target.querySelector('li[data-class="nextButton"]')
    if (parseInt(annualSalary.value) > 0) {
      nextButton.classList.remove("disabled")
    } else {
      nextButton.classList.add("disabled")
    }
  }

  // 住所詳細が変更されたとき
  addressChange() {
    const currentCompanyName = this.careerBody5Target.querySelector('input[type="text"')
    const submitButton = this.careerBody5Target.querySelector('button[type="submit"]')
    submitButton.disabled = !currentCompanyName.value
  }

  // 「次へ進む」ボタン
  nextButton(event) {
    // event.targetは<li>の中の<a>要素になるので、<li>要素を参照する
    const button = event.target.parentElement
    const current = this.element.querySelector(`[data-id=${button.dataset.currentModalBody}]`)
    const next = this.element.querySelector(`[data-id=${button.dataset.nextModalBody}]`)
    current.classList.add("non-display")
    next.classList.remove("non-display")
  }

  // 「１つ前に戻る」ボタン
  prevButton(event) {
    // event.targetは<li>の中の<a>要素になるので、<li>要素を参照する
    const button = event.target.parentElement
    const current = this.element.querySelector(`[data-id=${button.dataset.currentModalBody}]`)
    const prev = this.element.querySelector(`[data-id=${button.dataset.prevModalBody}]`)
    current.classList.add("non-display")
    prev.classList.remove("non-display")
  }

  // ダイアログを「閉じる」ボタン
  closeDialog(event) {
    const currentModalId = event.target.dataset.currentModal
    const dialog = document.querySelector(`[data-id=${currentModalId}]`)
    dialog.classList.remove("open")
  }
}

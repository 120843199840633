import $ from "jquery"

/**
 * 画面が表示されたとき、古いログイン情報のクッキーがあれば削除する
 */
$(window).on('load', () => {
    console.log("onload")
    const keys = document.cookie.split(';').map(c => c.split('=')[0].trim())
    const count = keys.filter(k => k == "_remogu_serivce_session").length
    const stcount = keys.filter(k => k == "_stage_remogu_serivce_session").length
    // _remogu_serivce_sessionか_stage_remogu_serivce_sessionが複数あるときに削除する
    if (count >= 2) {
      document.cookie="_remogu_serivce_session='';expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/"
    }
    if (stcount >= 2) {
      document.cookie="_stage_remogu_serivce_session='';expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/"
    }
  })
  
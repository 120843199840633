import { Controller } from "@hotwired/stimulus"

/*
  ユーザ退会画面のチェックボックスのハンドラ
*/
export default class extends Controller {
    static targets = ["userName", "mailAddr", "submit" ]

    connect() {
        console.log("user-withdraw connect")
    }

    // どちらかのチェックボックスが変わったときに呼ばれる
    checkChange() {
        console.log("user-withdraw checkChange")
        const userName = this.userNameTarget
        const mailAddr = this.mailAddrTarget
        // チェックボックスが両方チェックされたとき、ボタンを有効にする
        const submit = this.submitTarget
        submit.disabled = !(userName.checked && mailAddr.checked)
    }
}
